import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Background from "../images/Bg6.png"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

class ServicePageWebDesignTemplate extends React.Component {
    render() {
        const webdesignpage = this.props.data.contentfulWebdesign
        let title = webdesignpage.cityName + " Web Design | Professional Website Design Agency"
        let keywords = [webdesignpage.cityName + " Web Design", webdesignpage.cityName + " Custom Website Design", webdesignpage.cityName + " Website", webdesignpage.cityName + " Website Consulting"]
        let location = "/" + webdesignpage.slug
        let description = "Pyxel Development offers professional " + webdesignpage.cityName + " Website Design and website consulting services that are guaranteed to boost your business to the next level at an affordable rate."
        let isProduct = true
        let productName = webdesignpage.cityName + " Web Design"
        let sku = "10000004"
        return (
            <Layout>
                <Seo title={title} keywords={keywords} location={location} description={description} isProduct={isProduct} productName={productName} sku={sku} />
                <div className="bg-no-repeat bg-cover bg-left"
                    style={{
                        backgroundImage: `url(${Background})`,
                        width: '100%',
                        height: '100%',
                        backgroundAttachment: 'absolute',
                    }}>
                    <div className="container grid gap-x-5 lg:grid-cols-2 text-white font-bold p-10 pt-36 mx-auto xl:py-40">
                        <div className="flex justify-center lg:justify-end">
                            <div>
                                <p>SERVICE</p>
                                <h1 className="text-5xl md:text-6xl xl:text-7xl font-pixeboy" style={{ lineHeight: "80%" }}>
                                    {webdesignpage.cityName}<br />{webdesignpage.serviceType}
                                </h1>
                                <h2 className="text-md md:text-xl max-w-lg mt-7">
                                    CUSTOM & UNIQUE {webdesignpage.boldName} WEBSITE DESIGNS TO SET YOU APART FROM YOUR COMPETITORS
                                </h2>
                                <div className="max-w-xl font-normal text-xs md:text-base">
                                    <p className="mt-7 md:mt-12">
                                        When it comes to website designs, each organization is unique and requires an equally unique website design that is essential to stand out against your {webdesignpage.cityName} competition.<br /><br />
                                        Whether you are looking for a simple, modern website design or a custom website that matches your business’s theme, {webdesignpage.cityName + "'s"} Pyxel Development has you covered. We can help bring your
                                        vision to life by creating a fully custom & unique website design to satisfy the needs of your business.
                                    </p>
                                    <div className="hidden lg:block mt-10 md:mt-12 xl:mt-28">
                                        <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                            <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                                <span>Request Quote</span>
                                            </button>
                                        </Link>
                                        <div className="hover:text-gray-600 mt-4">
                                            <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                                Call Us: (916) 821-9044
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center mt-8 lg:mt-0 mx-auto max-w-xs md:max-w-md lg:max-w-xl lg:px-5 2xl:px-0">
                            <StaticImage src="../images/web-design-1.png" placeholder="none" alt={webdesignpage.cityName + " Website Design"} title={webdesignpage.cityName + " Website Design"} />
                        </div>
                        <div className="lg:hidden mx-auto mt-10 md:mt-12 xl:mt-28">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                    <span>Request Quote</span>
                                </button>
                            </Link>
                            <div className="hover:text-gray-600 mt-4">
                                <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                    Call Us: (916) 821-9044
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-banner">
                    <div className="py-10 md:py-24 px-5 -mb-8 lg:-mb-7">
                        <div className="text-white px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="mx-auto text-2xl font-bold md:text-4xl uppercase">
                                    Why Choose Pyxel Development?
                                </h2>
                                <p className="mt-2 text-xl">WE FOCUS ON THE ESSENTIALS:</p>
                                <div className="hidden md:grid md:grid-cols-3 gap-48">
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/stopwatch.png" placeholder="none" title={webdesignpage.cityName + " web design optimization"} alt={webdesignpage.cityName + " web design optimization"} />
                                        <h3>Speed Optimization</h3>
                                    </div>
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/user-experience.png" placeholder="none" title={webdesignpage.cityName + " web design user experience"} alt={webdesignpage.cityName + " web design user experience"} />
                                        <h3>Improving User Experience</h3>
                                    </div>
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/smartphone.png" placeholder="none" title={webdesignpage.cityName + " web design mobile optimization"} alt={webdesignpage.cityName + " web design mobile optimization"} />
                                        <h3>Mobile Compatibility</h3>
                                    </div>
                                </div>
                                <div className="hidden md:grid md:grid-cols-3 gap-40">
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/makeover.png" placeholder="none" title={webdesignpage.cityName + " web design aesthetics"} alt={webdesignpage.cityName + " web design aesthetics"} />
                                        <h3>Aesthetics</h3>
                                    </div>
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/quality.png" placeholder="none" title={webdesignpage.cityName + " web design quality"} alt={webdesignpage.cityName + " web design quality"} />
                                        <h3>Quality Content</h3>
                                    </div>
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/save.png" placeholder="none" title={webdesignpage.cityName + " web design efficiency"} alt={webdesignpage.cityName + " web design efficiency"} />
                                        <h3>Efficient Code</h3>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 md:hidden">
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/stopwatch.png" placeholder="none" title={webdesignpage.cityName + " web design optimization"} alt={webdesignpage.cityName + " web design optimization"} />
                                        <h3>Speed Optimization</h3>
                                    </div>
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/user-experience.png" placeholder="none" title={webdesignpage.cityName + " web design user experience"} alt={webdesignpage.cityName + " web design user experience"} />
                                        <h3>Improving User Experience</h3>
                                    </div>
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/smartphone.png" placeholder="none" title={webdesignpage.cityName + " web design mobile optimization"} alt={webdesignpage.cityName + " web design mobile optimization"} />
                                        <h3>Mobile Compatibility</h3>
                                    </div>
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/makeover.png" placeholder="none" title={webdesignpage.cityName + " web design aesthetics"} alt={webdesignpage.cityName + " web design aesthetics"} />
                                        <h3>Aesthetics</h3>
                                    </div>
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/quality.png" placeholder="none" title={webdesignpage.cityName + " web design quality"} alt={webdesignpage.cityName + " web design quality"} />
                                        <h3>Quality Content</h3>
                                    </div>
                                    <div className="py-10">
                                        <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/save.png" placeholder="none" title={webdesignpage.cityName + " web design efficiency"} alt={webdesignpage.cityName + " web design efficiency"} />
                                        <h3>Efficient Code</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white py-10 md:py-24 -mb-4 lg:-mb-5">
                    <h2 className="uppercase text-center font-bold text-2xl md:text-4xl pb-5 md:pb-10 2xl:pb-16">Website Design Solutions</h2>
                    <div className="md:grid md:grid-cols-2 container mx-auto px-5">
                        <div>
                            <p className="text-xs lg:text-lg text-center">
                                The Website Design process can be stressful, time-consuming, and can require you to make decisions that will directly affect your organization. Here at Pyxel Development, our goal is to make this process as
                                easy as possible by creating solutions to fit your budget and most importantly, the needs of your business!<br /><br />
                                We specialize in making your dreams a reality, whether you have a website design in mind or require a custom web design, Pyxel Development is here to help!
                            </p>
                        </div>
                        <div className="w-56 md:-mt-6 lg:w-80 mx-auto 2xl:-mt-10">
                            <StaticImage src="../images/layout.png" placeholder="none" title={webdesignpage.cityName + " website design agency"} alt={webdesignpage.cityName + " website design agency"} />
                        </div>
                    </div>
                </div>

                <div className="bg-offblue text-center py-10 md:py-24 px-5">
                    <h2 className="uppercase text-center text-white text-2xl md:text-4xl font-bold">When should you revise your website design?</h2>
                    <div className="lg:grid lg:grid-cols-3 container mx-auto gap-24 mt-7 px-10">
                        <div>
                            <h3 className="font-bold text-lg py-3">LOOKS OUTDATED</h3>
                            <p>Is your website design beginning to look out of style? Are there aspects of the design that you want to change? Your website is the first and most important interaction your customer receives with your brand.</p>
                            <StaticImage className="mb-8 md:mb-2 w-24 lg:w-32 mx-auto mt-5" src="../images/oldman.png" placeholder="none" title={webdesignpage.cityName + " web design update"} alt={webdesignpage.cityName + " web design update"} />
                        </div>
                        <div>
                            <h3 className="font-bold text-lg py-3">AMATEUR WEBSITE DESIGN</h3>
                            <p>Does your website design portray the important details while appealing to the user experience? Does your website design accurately portray the quality of your business?</p>
                            <StaticImage className="mb-8 md:mb-2 w-24 lg:w-32 mx-auto mt-5 lg:mt-16 xl:mt-5 2xl:mt-12" src="../images/joker.png" placeholder="none" title={webdesignpage.cityName + " poor web design"} alt={webdesignpage.cityName + " poor web design"} />
                        </div>
                        <div>
                            <h3 className="font-bold text-lg py-3">LONG LOAD TIMES</h3>
                            <p>If your website design seems sluggish or slow, it is proven that you are missing out on potential customers! Users and search engines do not prioritize slow pages.</p>
                            <StaticImage className="mt-3 md:-mb-6 w-24 lg:w-32 mx-auto mt-2 lg:mt-14 xl:mt-10 2xl:mt-16" src="../images/hourglass.png" placeholder="none" title={webdesignpage.cityName + " slow web design"} alt={webdesignpage.cityName + " slow web design"} />
                        </div>
                    </div>
                    <div className="lg:grid lg:grid-cols-3 container mx-auto gap-24 mt-7 px-10">
                        <div>
                            <h3 className="font-bold text-lg py-3">NOT MOBILE FRIENDLY</h3>
                            <p>About 50% of internet traffic comes from mobile devices. If your website design is not optimized for ALL screen sizes, you are missing out on a large number of clientele.</p>
                            <StaticImage className="mb-8 md:mb-2 w-24 lg:w-32 mx-auto mt-5 lg:mt-10 2xl:mt-16" src="../images/mobile.png" placeholder="none" title={webdesignpage.cityName + " mobile friendly web design"} alt={webdesignpage.cityName + " mobile friendly web design"} />
                        </div>
                        <div>
                            <h3 className="font-bold text-lg py-3">POOR ANALYTICS</h3>
                            <p>Analytics show the effectiveness of your website design. If your bounce rate is high or your website experiences short visit times, it is a direct indicator that the website design is of poor quality.</p>
                            <StaticImage className="mb-8 md:mb-2 w-24 lg:w-32 mx-auto mt-5 xl:mt-5 2xl:mt-12" src="../images/recession.png" placeholder="none" title={webdesignpage.cityName + " web design analytics"} alt={webdesignpage.cityName + " web design analytics"} />
                        </div>
                        <div>
                            <h3 className="font-bold text-lg py-3">DIFFICULT NAVIGATION</h3>
                            <p>Does your website design seem confusing to navigate or difficult to find certain sections? It is crucial that your website visitors are able to easily find and navigate to the desired page in at most 2-3 clicks.</p>
                            <StaticImage className="mb-8 md:mb-2 mt-3 w-24 lg:w-32 mx-auto mt-2 2xl:mt-10" src="../images/confused.png" placeholder="none" title={webdesignpage.cityName + " simple web design"} alt={webdesignpage.cityName + " simple web design"} />
                        </div>
                    </div>
                    <div className="flex justify-center md:-mt-10 -mb-4">
                        <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                            <button className="m-5 relative h-10 px-6 text-xs text-black transition-colors bg-transparent rounded-lg border border-black focus:shadow-outline hover:bg-white md:mt-20">
                                <span>Get Started <b>TODAY!</b></span>
                            </button>
                        </Link>
                    </div>
                </div>
            </Layout >
        )
    }
}
export default ServicePageWebDesignTemplate
export const pageQuery = graphql`
query ContentfulWebdesignBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulWebdesign( slug: {eq: $slug }) {
        title
        cityName
        boldName
        serviceType
        slug
    }
}
`